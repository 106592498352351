/* eslint-disable jsx-a11y/anchor-is-valid */
import React  from 'react'
import { useHistory } from "react-router-dom";

type Props = {
  className?: string
  image?: string
  title?: string
}

const DashboardDetail_Top: React.FC<Props> = ({className, image, title}) => {
  
  let history = useHistory();
  const redirectTo=()=>{
    history.push("/strategy/strategy_pool");
  }
  return (
    <>
    <div key=""
      className={`card bgi-no-repeat ${className}`}
      style={{
        backgroundPosition: 'right bottom',
        backgroundSize: '30% auto',
        backgroundImage: `url('/media/svg/background/blockchain.svg')`,
      }}
    >
      {/* begin::Body */}
      <div className='card-body'>
        <a href='#' className='card-title fw-bolder text-muted text-hover-primary fs-4'>
          {title}
        </a>

        <p
          className='text-dark-75 fw-bold fs-5 m-0'
        >
          <h1>We provide tons of strategies to <br/>make investing simple</h1>
        </p>
        
      </div>
      <div className='card-body' style={{
          backgroundPosition: 'left bottom',
        }}>
         <a href="#" className="btn btn-sm btn-primary" onClick={event=>redirectTo()}>Explore</a>
      </div>
      
      {/* end::Body */}
    </div>
    
    </>
  )
}

export {DashboardDetail_Top}
